import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
//const { distance, closest } = require("fastest-levenshtein")

function SearchBar() {
  const [searchInput, setSearchInput] = useState("")
  const [showOverlay, setShowOverlay] = useState(false)
  const [showSearchList, setShowSearchList] = useState(false)
  //const [data, setData] = useState()

  const onChangeSearchInput = event => {
    if (event.target.value) {
      setShowOverlay(true)
      setShowSearchList(true)
    }
    setSearchInput(event.target.value)
  }

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          frontmatter {
            title
            description
            path
          }
          id
        }
      }
    }
  `)

  // console.log(data.allMarkdownRemark.nodes)
  const dataArray = data.allMarkdownRemark.nodes.map(node => node.frontmatter)
  //console.log(dataArray[0])

  function calculateStringSimilarity(string1, string2) {
    const m = string1.length
    const n = string2.length

    if (m === 0) return n
    if (n === 0) return m

    let previousRow = Array.from({ length: n + 1 }, (_, j) => j)
    let currentRow = new Array(n + 1)

    for (let i = 1; i <= m; i++) {
      currentRow[0] = i

      for (let j = 1; j <= n; j++) {
        if (string1[i - 1] === string2[j - 1]) {
          currentRow[j] = previousRow[j - 1]
        } else {
          currentRow[j] = Math.min(
            previousRow[j - 1] + 1, // substitution
            currentRow[j - 1] + 1, // insertion
            previousRow[j] + 1 // deletion
          )
        }
      }

      // Swap previousRow and currentRow
      ;[previousRow, currentRow] = [currentRow, previousRow]
    }

    const levenshteinDistance = previousRow[n]

    // Calculate similarity score based on Levenshtein distance
    const maxLength = Math.max(m, n)
    const similarity = 1 - levenshteinDistance / maxLength

    return similarity
  }

  const key = searchInput.trim().toLowerCase()
  const keyWords = key.split(" ")

  const closestMatch = dataArray.map(blog => {
    const blogTitle = blog.title.toLowerCase()
    const blogDescription = blog.description.toLowerCase()

    // Check similarity between search input and title
    const titleSimilarity = calculateStringSimilarity(key, blogTitle)

    // Check similarity between search input and description
    const descriptionSimilarity = calculateStringSimilarity(
      key,
      blogDescription
    )

    let closestString, maxSimilarity

    if (titleSimilarity > descriptionSimilarity) {
      closestString = blogTitle
      maxSimilarity = titleSimilarity
    } else {
      closestString = blogDescription
      maxSimilarity = descriptionSimilarity
    }

    return { ...blog, closestString, maxSimilarity }
  })

  const filteredArray = closestMatch
    .filter(blog => {
      if (!key) return false

      const blogTitle = blog.title.toLowerCase()
      const blogDescription = blog.description.toLowerCase()

      for (let i = 0; i < keyWords.length; i++) {
        if (
          !blogTitle.includes(keyWords[i]) &&
          !blogDescription.includes(keyWords[i])
        ) {
          return false
        }
      }

      return true
    })
    .map(blog => {
      const blogTitle = blog.title.toLowerCase()
      const blogDescription = blog.description.toLowerCase()

      // Calculate relevance score
      let relevance = 0

      if (blogTitle.includes(key)) {
        relevance += 2 // Increase relevance score for title match
        relevance += blogTitle.indexOf(key) === 0 ? 1 : 0 // Increase relevance score for title match at the beginning
      }

      if (blogDescription.includes(key)) {
        relevance += 1 // Increase relevance score for description match
        relevance += blogDescription.indexOf(key) === 0 ? 1 : 0 // Increase relevance score for description match at the beginning
      }

      // Calculate similarity score between search input and closest string
      const closestStringSimilarity = calculateStringSimilarity(
        key,
        blog.closestString.toLowerCase()
      )
      relevance += closestStringSimilarity

      return { ...blog, relevance }
    })
    .sort((a, b) => b.relevance - a.relevance)

  if (filteredArray.length === 0) {
    closestMatch
      .filter(blog => {
        const blogTitle = blog.title.toLowerCase()
        const blogDescription = blog.description.toLowerCase()

        for (let i = 0; i < keyWords.length; i++) {
          if (
            !blogTitle.includes(keyWords[i]) &&
            !blogDescription.includes(keyWords[i])
          ) {
            return false
          }
        }

        return true
      })
      .sort((a, b) => b.maxSimilarity - a.maxSimilarity)
      .slice(0, 10)
      .map(blog => blog.title)
  }

  //console.log(filteredArray)

  return (
    <>
      {showOverlay && (
        <div
          className="fixed top-0 left-0 w-screen h-screen z-20 "
          onClick={() => {
            setShowOverlay(false)
            setShowSearchList(false)
          }}
        ></div>
      )}
      <div className="z-40 relative px-4 w-full sm:w-80 lg:w-64 xl:w-80 2xl:w-96">
        <div
          id="SearchBar"
          className="z-40 relative rounded flex justify-between bg-light-white items-center justify-self-center border border-gray-300 hover:border-new-purple"
        >
          <input
            type="text"
            id="SearchBox"
            placeholder="Search blogs ..."
            value={searchInput}
            autoComplete="off"
            onChange={onChangeSearchInput}
            onKeyUp={onChangeSearchInput}
            onFocus={onChangeSearchInput}
            onInput={onChangeSearchInput}
            //onBlur={onBlurHandler}
            className="z-40 w-11/12 px-4 py-3 text-sm text-gray-700 bg-light-white rounded tracking-wider hover:text-gray-900 border-none block outline-none pb-3"
          />
          <div
            id="SearchIcon"
            className="z-40 outline-none pr-4 text-new-green cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-search"
            >
              <circle cx="11" cy="11" r="8" />
              <line x1="21" y1="21" x2="16.65" y2="16.65" />
            </svg>
          </div>
        </div>

        {showSearchList && (
          <div
            className="absolute top-15 z-40 w-full sm:w-80 lg:w-72 xl:w-80 2xl:w-96 mt-1 bg-light-white max-h-96 overflow-y-scroll rounded-md shadow-md"
            //onClick={onFocusHandler}
          >
            {filteredArray.map(blog => (
              <a href={blog.path}>
                <div className="px-4 py-2 border text-sm 2xl:text-base tracking-wider text-gray-600 hover:underline hover:text-new-blue transition duration-150">
                  <h1>{blog.title}</h1>
                </div>
              </a>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default SearchBar
