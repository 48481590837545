import React, { useState } from "react"
import { Link } from "gatsby"
import eaLogo from "../img/EaLogo.png"
import SearchBar from "./SearchBar"

const NavigationBar = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [isOpenCourse, setIsOpenCourse] = useState(false)
  const [isOpenBlog, setIsOpenBlog] = useState(false)

  const handleMenuButtonClick = () => {
    setMenuOpen(prevState => !prevState)
  }

  const handleHover = type => {
    if (type === "course") {
      setIsOpenCourse(prevState => !prevState)
      setIsOpenBlog(false)
    } else if (type === "blog") {
      setIsOpenBlog(prevState => !prevState)
      setIsOpenCourse(false)
    }
  }

  return (
    <div>
      <div className="bg-white">
        <div className="container px-2 sm:px-3 xl:px-5 2xl:px-6 mx-auto flex flex-wrap items-center justify-between py-2">
          <div className="flex justify-between w-full lg:w-auto lg:block">
            <Link to="/" className="z-30">
              <img
                className="w-32 h-12 xl:w-36 xl:h-14 2xl:w-44 2xl:h-16"
                src={eaLogo}
                alt="enjoyalgorithms"
              />
            </Link>

            <div className="hidden mt-2 sm:block lg:hidden">
              <SearchBar />
            </div>

            <div className="flex m-1 flex-wrap items-end">
              <button
                className="block p-3 text-xl leading-none text-black border border-solid rounded-lg outline-none cursor-pointer lg:hidden focus:outline-none"
                type="button"
                id="menuButton"
                aria-label="Menu Icon"
                onClick={handleMenuButtonClick}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-menu"
                >
                  <line x1="3" y1="12" x2="21" y2="12" />
                  <line x1="3" y1="6" x2="21" y2="6" />
                  <line x1="3" y1="18" x2="21" y2="18" />
                </svg>
              </button>
            </div>
          </div>

          <div className="flex justify-end">
            <div className="hidden mt-1 mr-4 mb-1 lg:block">
              <SearchBar />
            </div>
            <div
              className={
                "lg:flex flex-grow items-center" +
                (menuOpen ? " flex" : " hidden")
              }
              id="example-navbar-info"
            >
              <ul className="flex mt-4 lg:mt-0 flex-col tracking-wider text-base sm:text-sm 2xl:text-base text-new-blue sm:text-gray-600 list-none sm:flex-row">
                <li className="z-30">
                  <Link
                    className="flex px-3 sm:hidden py-2 hover:text-new-blue"
                    to="/courses/"
                  >
                    Courses
                  </Link>
                  <div
                    className="relative hidden sm:block"
                    onMouseEnter={() => handleHover("course")}
                    onMouseLeave={() => handleHover("course")}
                  >
                    <button className="inline-flex tracking-wider w-full justify-center bg-light-white px-3 py-2 focus:outline-none">
                      Courses
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-chevron-down"
                      >
                        <polyline points="6 9 12 15 18 9" />
                      </svg>
                    </button>

                    {isOpenCourse && (
                      <div className="absolute z-10 w-60 2xl:w-72 bg-light-white drop-shadow-lg tracking-wider border border-gray-300 rounded-lg bg-light-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Link
                            className="flex px-3 py-3 hover:underline hover:text-new-blue"
                            to="/data-structures-and-algorithms-course/"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-code"
                            >
                              <polyline points="16 18 22 12 16 6" />
                              <polyline points="8 6 2 12 8 18" />
                            </svg>
                            <p className="ml-2">Coding Interview Course</p>
                          </Link>
                          <Link
                            className="flex px-3 py-3 hover:underline hover:text-new-blue "
                            to="/machine-learning-courses/"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-cpu"
                            >
                              <rect
                                x="4"
                                y="4"
                                width="16"
                                height="16"
                                rx="2"
                                ry="2"
                              />
                              <rect x="9" y="9" width="6" height="6" />
                              <line x1="9" y1="1" x2="9" y2="4" />
                              <line x1="15" y1="1" x2="15" y2="4" />
                              <line x1="9" y1="20" x2="9" y2="23" />
                              <line x1="15" y1="20" x2="15" y2="23" />
                              <line x1="20" y1="9" x2="23" y2="9" />
                              <line x1="20" y1="14" x2="23" y2="14" />
                              <line x1="1" y1="9" x2="4" y2="9" />
                              <line x1="1" y1="14" x2="4" y2="14" />
                            </svg>
                            <p className="ml-2">Machine Learning Course</p>
                          </Link>
                          <Link
                            className="flex px-3 py-3 hover:underline hover:text-new-blue "
                            to="/system-design-courses/"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-server"
                            >
                              <rect
                                x="2"
                                y="2"
                                width="20"
                                height="8"
                                rx="2"
                                ry="2"
                              />
                              <rect
                                x="2"
                                y="14"
                                width="20"
                                height="8"
                                rx="2"
                                ry="2"
                              />
                              <line x1="6" y1="6" x2="6.01" y2="6" />
                              <line x1="6" y1="18" x2="6.01" y2="18" />
                            </svg>
                            <p className="ml-2">System Design Course</p>
                          </Link>
                          <Link
                            className="flex px-3 py-3 hover:underline hover:text-new-blue "
                            to="/oops-course/"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-credit-card"
                            >
                              <rect
                                x="1"
                                y="4"
                                width="22"
                                height="16"
                                rx="2"
                                ry="2"
                              />
                              <line x1="1" y1="10" x2="23" y2="10" />
                            </svg>
                            <p className="ml-2">OOPS Design Course</p>
                          </Link>

                          <Link
                            className="flex px-3 py-3 hover:underline hover:text-new-blue "
                            to="/data-science-course/"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-database"
                            >
                              <ellipse cx="12" cy="5" rx="9" ry="3" />
                              <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3" />
                              <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5" />
                            </svg>
                            <p className="ml-2">Data Science Course</p>
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </li>

                <li className="z-30">
                  <Link
                    className="flex px-3 sm:hidden py-2 hover:text-new-blue "
                    to="/coding-interview/"
                  >
                    Blogs
                  </Link>
                  <div
                    className="relative hidden sm:block"
                    onMouseEnter={() => handleHover("blog")}
                    onMouseLeave={() => handleHover("blog")}
                  >
                    <button className="inline-flex tracking-wider w-full justify-center bg-light-white px-3 py-2 focus:outline-none">
                      Blogs
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-chevron-down"
                      >
                        <polyline points="6 9 12 15 18 9" />
                      </svg>
                    </button>

                    {isOpenBlog && (
                      <div className="absolute z-10 w-60 2xl:w-72 bg-light-white drop-shadow-lg tracking-wider border border-gray-300 rounded-lg bg-light-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Link
                            className="flex px-3 py-3 hover:underline hover:text-new-blue"
                            to="/coding-interview/"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-code"
                            >
                              <polyline points="16 18 22 12 16 6" />
                              <polyline points="8 6 2 12 8 18" />
                            </svg>
                            <p className="ml-2">Coding Interview Blogs</p>
                          </Link>
                          <Link
                            className="flex px-3 py-3 hover:underline hover:text-new-blue "
                            to="/machine-learning/"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-cpu"
                            >
                              <rect
                                x="4"
                                y="4"
                                width="16"
                                height="16"
                                rx="2"
                                ry="2"
                              />
                              <rect x="9" y="9" width="6" height="6" />
                              <line x1="9" y1="1" x2="9" y2="4" />
                              <line x1="15" y1="1" x2="15" y2="4" />
                              <line x1="9" y1="20" x2="9" y2="23" />
                              <line x1="15" y1="20" x2="15" y2="23" />
                              <line x1="20" y1="9" x2="23" y2="9" />
                              <line x1="20" y1="14" x2="23" y2="14" />
                              <line x1="1" y1="9" x2="4" y2="9" />
                              <line x1="1" y1="14" x2="4" y2="14" />
                            </svg>
                            <p className="ml-2">Machine Learning Blogs</p>
                          </Link>
                          <Link
                            className="flex px-3 py-3 hover:underline hover:text-new-blue "
                            to="/system-design/"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-server"
                            >
                              <rect
                                x="2"
                                y="2"
                                width="20"
                                height="8"
                                rx="2"
                                ry="2"
                              />
                              <rect
                                x="2"
                                y="14"
                                width="20"
                                height="8"
                                rx="2"
                                ry="2"
                              />
                              <line x1="6" y1="6" x2="6.01" y2="6" />
                              <line x1="6" y1="18" x2="6.01" y2="18" />
                            </svg>
                            <p className="ml-2">System Design Blogs</p>
                          </Link>
                          <Link
                            className="flex px-3 py-3 hover:underline hover:text-new-blue "
                            to="/oops-concepts/"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-credit-card"
                            >
                              <rect
                                x="1"
                                y="4"
                                width="22"
                                height="16"
                                rx="2"
                                ry="2"
                              />
                              <line x1="1" y1="10" x2="23" y2="10" />
                            </svg>
                            <p className="ml-2">OOPS Design Blogs</p>
                          </Link>
                          <a
                            href="https://www.enjoymathematics.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex px-3 py-3 hover:underline hover:text-new-blue"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-external-link"
                            >
                              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                              <polyline points="15 3 21 3 21 9"></polyline>
                              <line x1="10" y1="14" x2="21" y2="3"></line>
                            </svg>
                            <p className="ml-2 tracking-wider">
                              EnjoyMathematics
                            </p>
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </li>

                <li className="z-30">
                  <Link
                    className="flex px-3 py-2 hover:underline hover:text-new-blue "
                    to="/popular-tags/"
                  >
                    Tags
                  </Link>
                </li>

                <li className="z-30">
                  <Link
                    className="flex px-3 py-2 hover:underline hover:text-new-blue "
                    to="/reviews-and-testimonials/"
                  >
                    Reviews
                  </Link>
                </li>
                <li className="z-30">
                  <Link
                    className="flex px-3 py-2 hover:underline hover:text-new-blue "
                    to="/stories/"
                  >
                    Stories
                  </Link>
                </li>

                <li className="z-30">
                  <Link
                    className="flex px-3 py-2 hover:underline hover:text-new-blue "
                    to="/contact/"
                  >
                    Contact Us
                  </Link>
                </li>

                <li className="z-30">
                  <div className="tracking-wider sm:hidden hover:underline text-new-blue sm:text-gray-600 hover:text-new-blue px-3 py-2">
                    <a
                      href="https://www.enjoymathematics.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className="mr-2 tracking-wider">EnjoyMathematics</p>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:hidden flex justify-center">
        <SearchBar />
      </div>
    </div>
  )
}

export default NavigationBar